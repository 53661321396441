<template>
  <div id="general-dashboard" v-show="loaded">
    <div class="vx-row">
      <div class="vx-col w-full mb-base" v-show="showRemindersRoles.includes($store.state.AppActiveUser.employee.nik) == true && unreadNotifications.length > 0">
        <div class="bg-primary py-3 px-5 text-white whitespace-nowrap rounded-lg">
          <span class="font-medium text-xl">Reminders: </span>
          <marquee-text
            :duration="40"
          >
            &nbsp; | {{ unreadNotifications.map(reminder => reminder.msg).join(' | ') }}
          </marquee-text>
        </div>
      </div>

      <!-- CARD 1: CONGRATS -->
      <div class="vx-col w-full lg:w-2/3 mb-base">
        <vx-card
          slot="no-body"
          class="text-center bg-primary-gradient greet-user mb-base relative"
        >
        <Particles
                id="tsparticles"
                :options="particleOptions"
        />

          <img
            src="@/assets/images/elements/decore-left.png"
            class="decore-left"
            alt="Decore Left"
            width="200"
          />
          <img
            src="@/assets/images/elements/decore-right.png"
            class="decore-right"
            alt="Decore Right"
            width="175"
          />
          <div class="p-8">
            <feather-icon
            icon="BellIcon"
            class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
            svgClasses="h-8 w-8"
          ></feather-icon>
          <h1 class="mb-6 text-white">
            <span style="font-weight: 400;">Welcome back</span>
            {{ $store.state.AppActiveUser.employee.name.split(" ")[0] }}
            <feather-icon icon="SmileIcon"></feather-icon>
          </h1>
          <vue-typed-js 
            v-if="summary" 
            :strings="summary.split('.')"
            :fadeOut="true"
          >
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white typing"></p>
          </vue-typed-js>
          </div>
        </vx-card>

        <div class="vx-row">
          <div
            class="vx-col w-full sm:w-1/2"
            :style="`cursor: pointer;`"
            @click="$router.push({ name: 'request-driver' })"
          >
            <statistics-card-line
              hideChart
              icon="TruckIcon"
              :statistic="requestDriverNew.length"
              :statisticTitle="$i18n.t('NewRequestDriver')"
            />
          </div>

          <div
            class="vx-col w-full sm:w-1/2"
            :style="`cursor: pointer;`"
            @click="$router.push({ name: 'request-messenger' })"
          >
            <statistics-card-line
              hideChart
              icon="UserIcon"
              :statistic="requestMessengerNew.length"
              :statisticTitle="$i18n.t('NewRequestMessenger')"
            />
          </div>
        </div>
      </div>

      <!-- CUSTOMERS CHART -->
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <vx-card :title="`${$i18n.t('Attendance')}`" style="height: 100%;">
          <!-- SLOT = ACTIONS -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{ attendancePieOption }}</span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svgClasses="h-4 w-4"
                  class="ml-1"
                />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item
                  @click="
                    () => {
                      attendancePieOption = 'This week';
                    }
                  "
                  >This week</vs-dropdown-item
                >
                <vs-dropdown-item
                  @click="
                    () => {
                      attendancePieOption = 'This month';
                    }
                  "
                  >This month</vs-dropdown-item
                >
                <vs-dropdown-item
                  @click="
                    () => {
                      attendancePieOption = 'This year';
                    }
                  "
                  >This year</vs-dropdown-item
                >
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <div
            v-if="puncNow.year.options"
            slot="no-body"
            style="padding: 20px 0px 10px 0px;"
          >
            <!-- CHART -->
            <vue-apex-charts
              type="pie"
              height="334"
              class="mb-base"
              :options="puncNow.year.options"
              :series="puncNow.year.series"
            />

            <!-- CHART DATA -->
            <!--            <ul class="mb-1">-->
            <!--              <li v-for="(label, i) in puncNow.year.options.labels" :key="i" class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0">-->
            <!--                                <span class="flex items-center">-->
            <!--                                    <span class="inline-block h-3 w-3 rounded-full mr-2" :style="`background: ${puncNow.year.options.colors[i]}`"></span>-->
            <!--                                    <span class="font-semibold">{{ label }}</span>-->
            <!--                                </span>-->
            <!--                <span>{{ puncNow.year.series[i] }}</span>-->
            <!--              </li>-->
            <!--            </ul>-->
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <!-- CARD 9: DISPATCHED ORDERS -->
      <div class="vx-col w-full">
        <vx-card title="Job Active">
          <div slot="no-body" class="mt-4">
            <vs-table :data="jobsActive" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>DATE</vs-th>
                <vs-th>JOB TITLE</vs-th>
                <vs-th>PIC 1</vs-th>
                <vs-th>PIC 2</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].date">
                    <span>{{ data[indextr].date }}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].title">
                    <span
                      class="text-primary"
                      style="cursor: pointer;"
                      @click.prevent="
                        $router.push({
                          name: 'job-tracking-show',
                          params: { id: $secure.encrypt(data[indextr].id) }
                        })
                      "
                      >{{ data[indextr].title }}</span
                    >
                  </vs-td>
                  <vs-td :data="data[indextr].pic1">
                    <span>{{ data[indextr].pic1.employee.name }}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].pic2">
                    <span>{{ data[indextr].pic2.employee.name }}</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import { colors } from "../../themeConfig";
import particleConfig from '@/assets/vendor/vue-particles/particles.json'
import axios from "@/axios";
import recipe from "@/recipe";
import fb from "@/firebase/firebaseConfig";
import "firebase/messaging";

import ChangeTimeDurationDropdown from "@/components/ChangeTimeDurationDropdown.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      isPaused: false,
      unreadNotifications: [],
      particleOptions: {},
      loaded: false,
      summary: "",
      month: "",
      date: "",
      requestDriverActive: [],
      requestDriverNew: [],
      requestMessengerActive: [],
      requestMessengerNew: [],
      puncNow: {
        year: {
          options: {},
          series: []
        },
        month: {
          options: {},
          series: []
        },
        week: {
          options: {},
          series: []
        }
      },
      jobsActive: [],
      attendancePieOption: "This week"
    };
  },

  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown
  },

  computed: {
    showRemindersRoles () {
      return [17605001, 49315029, 49918045, 37219049, 2015090000]
    }
  },

  watch: {
    attendancePieOption() {
      this.loadPuncNow(this.attendancePieOption);
    }
  },

  methods: {
    loading() {
      this.loaded = false;

      this.$vs.loading({
        type: "border",
        color: "primary",
        text: `Wait a minute, It's getting data.`
      });
    },
    loadDriverActive() {
      // Load Request Driver Active
      let params = {
        type: "driver"
      };

      this.$store
        .dispatch("request/fetchItems", { params })
        .then(resp => {
          this.requestDriverActive = resp.data.values.items.filter(
            item => item.status == 1
          );
          this.requestDriverNew = resp.data.values.items.filter(
            item => item.status == 2
          );
        })
        .catch(err => {
          if (!err.response) {
            // network error
            err.response = {
              data: {
                message: "Server error"
              }
            };
          }

          this.$vs.notify({
            title: "Opps something error",
            text: err.response.data.message,
            color: "danger"
          });
        });
    },
    loadMessengerActive() {
      // Load Request Messenger Active
      let params = {
        type: "messenger"
      };

      this.$store
        .dispatch("request/fetchItems", { params })
        .then(resp => {
          this.requestMessengerActive = resp.data.values.items.filter(
            item => item.status == 1
          );
          this.requestMessengerNew = resp.data.values.items.filter(
            item => item.status == 2
          );
        })
        .catch(err => {
          if (!err.response) {
            // network error
            err.response = {
              data: {
                message: "Server error"
              }
            };
          }

          this.$vs.notify({
            title: "Opps something error",
            text: err.response.data.message,
            color: "danger"
          });
        });
    },
    loadPuncNow(option) {
      if (option == "This week") {
        axios
          .get("/dashboard/punctuality-now/week")
          .then(response => {
            this.puncNow.year.options = {
              labels: response.data.labels,
              dataLabels: {
                enabled: true,
                formatter: function(val) {
                  return val.toFixed(2) + "%";
                },
                dropShadow: {}
              },
              legend: { show: true, position: "bottom" },
              chart: {
                type: "pie",
                offsetY: 0,
                dropShadow: {
                  enabled: false,
                  blur: 5,
                  left: 1,
                  top: 1,
                  opacity: 0.2
                },
                toolbar: {
                  show: false
                }
              },
              stroke: {
                width: 5
              },
              colors: Object.values(colors)
            };
            this.puncNow.year.series = response.data.series;
            this.month = response.data.month;
          })
          .catch(error => {
            this.$catchErrorResponse(error)
          })
          .finally(() => {
            this.onLoaded();
          });
      } else if (option == "This month") {
        axios
          .get("/dashboard/punctuality-now/month")
          .then(response => {
            this.puncNow.year.options = {
              labels: response.data.labels,
              dataLabels: {
                enabled: true,
                formatter: function(val) {
                  return val.toFixed(2) + "%";
                },
                dropShadow: {}
              },
              legend: { show: true, position: "bottom" },
              chart: {
                type: "pie",
                offsetY: 0,
                dropShadow: {
                  enabled: false,
                  blur: 5,
                  left: 1,
                  top: 1,
                  opacity: 0.2
                },
                toolbar: {
                  show: false
                }
              },
              stroke: {
                width: 5
              },
              colors: Object.values(colors)
            };
            this.puncNow.year.series = response.data.series;
            this.month = response.data.month;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.onLoaded();
          });
      } else if (option == "This year") {
        axios
          .get("/dashboard/punctuality-now/year")
          .then(response => {
            this.puncNow.year.options = {
              labels: response.data.labels,
              dataLabels: {
                enabled: true,
                formatter: function(val) {
                  return val.toFixed(2) + "%";
                },
                dropShadow: {}
              },
              legend: { show: true, position: "bottom" },
              chart: {
                type: "pie",
                offsetY: 0,
                dropShadow: {
                  enabled: false,
                  blur: 5,
                  left: 1,
                  top: 1,
                  opacity: 0.2
                },
                toolbar: {
                  show: false
                }
              },
              stroke: {
                width: 5
              },
              colors: Object.values(colors)
            };
            this.puncNow.year.series = response.data.series;
            this.month = response.data.month;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.onLoaded();
          });
      }
    },
    loadSummary() {
      axios.get("/dashboard/summary").then(resp => {
        this.summary = resp.data;
      });
    },
    getNotification(){
      axios.get('reminders/notification')
      .then(({data}) => {
        this.unreadNotifications = data
      })
    },
    onLoaded() {
      this.loaded = true;
      this.$vs.loading.close();
    },
    getNotificationFCM() {
      const messaging = fb.messaging();

      if (Notification.permission !== "granted") {
        messaging
          .requestPermission()
          .then(async function () {
            const token = await messaging.getToken();

            saveSubscribedToken(token);
          })
          .catch(function (err) {
            console.log("Unable to get permission to notify.", err);
          });
      }

      function saveSubscribedToken(token) {
      axios
        .post(
          `${recipe}/api/v1/auth/subscribe-push-notification`, {token}
        )
        .then(({ data: res }) => console.log(res))
        .catch((err) => console.log(err.response));
      }
    },
  },

  created() {
    this.loading();
    this.loadSummary();
    this.loadDriverActive();
    this.loadMessengerActive();
    this.loadPuncNow("This week");
    this.getNotification()

    // Job Active
    axios
      .get("/jobs/2024")
      .then(response => {
        this.jobsActive = response.data.values;
      })
      .catch(error => {
        console.log(error);
      });
  },

  mounted () {
    this.particleOptions = particleConfig;
    this.getNotificationFCM()
  },

  updated () {
    if (typeof(Event) === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      // causes deprecation warning on modern browsers
      var evt = window.document.createEvent('UIEvents'); 
      evt.initUIEvent('resize', true, false, window, 0); 
      window.dispatchEvent(evt);
    }
  },
};
</script>

<style lang="scss">
.decore-left {
  position: absolute;
  left: 0;
  top: 0;
}
.decore-right {
  position: absolute;
  right: 0;
  top: 0;
}

#general-dashboard .vx-card .vx-card__collapsible-content .vx-card__body {
  position: relative;
  padding: 0 !important;
}

#general-dashboard #tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
}

#general-dashboard #tsparticles .tsparticles-canvas-el {
  position: absolute !important;
}

span.typed-cursor {
  color: transparent !important;
}
</style>
